import React from 'react'
import SVG from '../../components/SVG'

const Filter = (props) => {
  return (
    <SVG viewBox="0 0 82 82" {...props}>
      <g>
        <line x1="34.94" y1="31.35" x2="65.25" y2="31.35" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.61" />
        <rect x="24.54" y="24.93" width="10.13" height="12.43" rx="4.06" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.61" />
        <line x1="18.64" y1="51.06" x2="43.78" y2="51.06" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.61" />
        <line x1="55.35" y1="51.06" x2="65.25" y2="51.06" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.61" />
        <rect x="44.49" y="44.84" width="10.13" height="12.43" rx="4.06" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.61" />
        <line x1="18.64" y1="31.35" x2="23.45" y2="31.35" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.61" />
      </g>
    </SVG>
  )
}

export default Filter
