import React from 'react'
import { Container } from '@chakra-ui/react'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Text from '../../components/Text'
import SeeMore from '../../components/SeeMore'
import BackgroundImage from '../../components/BackgroundImage'
import { Media, responsive } from '../../contexts/responsive'

import Projects from './Projects'
import collection from './collection.svg'
import collection_desk from './collection_desk.svg'
import recommend from './legend-recommend.svg'
import find from './legend-find.svg'
// import usePortfolio from './usePortfolio'

const legends = [recommend, find]

const Portfolio = () => {
  return (
    <Box pt={responsive('2.5em', '5.5em')}>
      <Container maxWidth={responsive('33.5em', '68.75em')} px="0">
        <Flex
          alignItems={responsive('auto', 'center')}
          flexDirection={responsive('row', 'column-reverse')}
          pr={responsive('1.25em', 0)}
          pl={responsive('2.25em', 0)}
        >
          <Box flex={1} mr={responsive('2.5em', 0)} pt={responsive('0', '2.5em')}>
            <Box pb="1.125em" borderBottom="1px solid" borderColor="custom.borderGray" px={responsive(0, '10.625em')}>
              <Text.Bold lineHeight="1" textAlign={responsive('left', 'center')} fontSize={responsive('1.75em', '3.75em')}>作品選集</Text.Bold>
              <Text mt="0.75rem" fontSize={responsive('0.875em', '1.875em')} color="custom.textGray">「這世界上不存在完美的作品，只有最適合的溝通體驗」<br />如果想了解更多製作過程，歡迎填寫表單私下詢問</Text>
            </Box>
            <Media lessThan="md">
              <Text.Bold mt="1.125rem" fontSize="0.875em" color="custom.textColor">
                點選以下兩種篩選器<br/>看看分別有哪些作品吧！
              </Text.Bold>
            </Media>
            <Media greaterThanOrEqual="md">
              <Text.Bold mt="1.5em" textAlign="center" fontSize="1.875em" color="custom.textColor">
                點選以下兩種篩選器，看看分別有哪些作品吧！
              </Text.Bold>
            </Media>
            <Flex justifyContent={'space-between'} flexDirection={responsive('column', 'row')} mt={responsive('1.25em', '2.5em')}>
              {legends.map((src, i) => (
                <Box width={responsive('100%', '47.5%')} mt={responsive(i && '1.125em', 0)} key={i}>
                  <BackgroundImage src={src} ratio={540 / 195}  />
                </Box>
              ))}
            </Flex>
          </Box>
          <Box width={responsive('2.5em', '66%')}>
            <Media lessThan="lg">
              <Box>
                <BackgroundImage ratio={92.08 / 729} src={collection} />
              </Box>
            </Media>
            <Media greaterThanOrEqual="lg">
              <Box>
                <BackgroundImage ratio={728.24 / 92.08} src={collection_desk} />
              </Box>
            </Media>
          </Box>
        </Flex>
      </Container>
      <Projects />
      <SeeMore page="collection" />
    </Box>
  )
}

export default Portfolio
