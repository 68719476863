import React from 'react'
import SVG from '../../components/SVG'

const Smile = (props) => {
  return (
    <SVG viewBox="0 0 73.58 72.27" {...props}>
      <circle cx="46.36" cy="16.57" r="2.25" fill="currentColor" />
      <path d="M28.54,14.32a2.25,2.25,0,1,1-2.25,2.25A2.25,2.25,0,0,1,28.54,14.32Z" fill="currentColor" />
      <path d="M43.07,23.26a11.74,11.74,0,0,1-10.61,0A1.69,1.69,0,0,1,31.74,21a1.67,1.67,0,0,1,1.52-.91h9.12a1.69,1.69,0,0,1,.69,3.19Z" fill="currentColor" />
      <path d="M38,33.56l0-.09c-1.9-7.58-18.52-.3-23,.82-.32.07-.64-1.57-.68-1.79-.72-4-3.18-8.21-7-9.94-2.65-1.21-5.69.24-5.81,3.29C1.4,28.47,2.76,31,3,33.63c.33,3.12-.68,6-.93,9-.62,7.61,1.16,17.55,6.75,23.17,7.94,8,22.35,1.08,30.47-2.88,1.68-.82,2.92-2.35,2.54-4.31a1.22,1.22,0,0,0-.06-.26l-.12-.5A3.91,3.91,0,0,0,37,55a3.9,3.9,0,0,0,2.85-4.73l-.13-.5A3.9,3.9,0,0,0,34.94,47l1.32-.33a4.11,4.11,0,0,0,3-5l0-.09a4.11,4.11,0,0,0-5-3l.94-.23A3.94,3.94,0,0,0,38,33.56Z" fill="white" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
    </SVG>
  )
}

export default Smile
