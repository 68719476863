import { Collapse, useDisclosure } from '@chakra-ui/react'
import includes from 'lodash/includes';
import React from 'react'
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { responsive } from '../contexts/responsive';

import Box from './Box'
import Button from './Button'

const CollapseEx = ({ data, children, selectState }) => {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <>
      <Button.Transparent
        py="0.75rem"
        width="100%"
        color="black"
        justifyContent="space-between"
        borderRadius="0"
        borderBottom="1px solid"
        borderColor="borderGray"
        onClick={onToggle}
        fontSize={responsive('1em', '1.5em')}
      >
        {children}
        <Box>
          {isOpen ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
        </Box>
      </Button.Transparent>
      <Collapse in={isOpen} animateOpacity>
        <Box
          px='1.875em'
          pb='0.75em'
          shadow='sm'
        >
          {data.map((d, i) => (
            <Button.Outline
              fontSize={responsive('0.875em', '1.375em')}
              px="0.875rem"
              py="0.5rem"
              mt="0.75em"
              mx="0.25em"
              trackerName={d.trackerName}
              bg={includes(selectState.value, d.label) ? 'black' : 'white'}
              color={includes(selectState.value, d.label) ? 'white' : 'black'}
              onClick={() => {
                if(includes(selectState.value, d.label)) {
                  const key = +selectState.value.findIndex(o => o == d.label)
                  selectState.value.splice(key, 1)
                  selectState.func([...selectState.value])
                  return
                }
                selectState.value.push(d.label)
                selectState.func([...selectState.value])
              }}
              key={i}
            >{d.label}</Button.Outline>
          ))}
        </Box>
      </Collapse>
    </>
  )
}

export default CollapseEx
